
import { defineComponent } from "vue";

export default defineComponent({
  name: "Contact",
  data() {
    return {
      firstEmail: `< ingrid@studioigoringrid.se >`,
      secondEmail: `< igor@studioigoringrid.se >`,
    };
  },
});
